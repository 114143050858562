@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,600&family=Bebas+Neue&family=Lilita+One&family=Passion+One&family=Patua+One&family=Paytone+One&family=Tenor+Sans&family=Sriracha&family=DM+Serif+Display&family=Prata&display=swap');

.modal {
  /* taken from https://www.w3schools.com/howto/howto_css_modals.asp */
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(100,100,100,0.4);
}

.overflow-y-hidden {
  overflow: hidden;
}

/**** ALERT MODAL STYLES ****/

.alert-modal {
  background-color: white;
  /* font-family: 'Inter', sans-serif; */
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-sizing: border-box;
  width: 500px;
  max-width: 90vw;
}

.alert-modal-inner {
  display: flex;
  flex-direction: column;
  margin: 25px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.alert {
  text-align: center;
}

.alert-header {
  text-align: center;
  font-family: Bebas Neue;
  font-size: 34px;
}

.alert-body {
  margin: 15px;
}

.alert-modal .export-close {
  margin-top: 10px;
}

.alert-modal .export-close-button {
  font-size: 16px;
}

.alert-modal .export-close-button span {
  margin-left: 2px;
}

@media screen and (max-width: 415px) {
  .alert-header {
    font-size: 29px;
  }
  .alert-body, .alert-modal .export-close-button {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .alert-header {
    font-size: 26px;
  }
  .alert-body, .alert-modal .export-close-button {
    font-size: 14px;
  }
}

@media screen and (max-width: 330px) {
  .alert-header {
    font-size: 25px;
  }
  .alert-body, .alert-modal .export-close-button {
    font-size: 13px;
  }
}

/***** EXPORT MODAL STYLES *****/

.export-modal-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}



.export-modal {
  /* border: 1px red solid; */
  background-color: white;
  font-family: 'Inter', sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* padding: 20px; */
  padding: calc(min(3vw, 3vh));
  /* height: 80vh; */
  max-height: 800px;
}
.export-modal .info-close-button-mini {
  display: none;
}

.export-modal img {
  display: block;
  height: 65cqh;
  max-height: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
}

.export-buttons {
  /* border: 1px blue solid; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2.5cqh 0;
  height: 7cqh
}

.export-button {
  /* border: 1px green solid; */
  color: white;
  background-color: black;
  font-weight: bold;
  width: 42%;
  height: 100%;
  font-size: 2.8cqh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: scale(1);
  -webkit-tap-highlight-color: transparent;
}

.export-button span {
  margin-left: 0.4cqw;
}

.export-close {
  /* border: 1px pink solid; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.export-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2.4cqh;
  color: black;
  opacity: 0.4;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.export-close-button span {
  margin-left: 0.2cqw;
}

@media screen and (max-width: 500px) {
  .export-modal img {
    width: 85cqw;
    height: auto;
    /* max-height: 600px; */
  }
}

@media screen and (max-height: 400px) {
  .export-modal img {
    height: 85cqh;
    width: auto;
    /* max-height: 600px; */
  }
}

