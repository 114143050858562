@media screen and (prefers-reduced-motion: no-preference) {
  .example-image #export-image .nominees {
    width: 300%;
  }
  
  .example-image #export-image .award {
    overflow: hidden;
  }
  
  .example-image #export-image .award:nth-child(1) .nominees {
    animation: slideLeft 85s linear infinite;
  }
  .example-image #export-image .award:nth-child(2) .nominees {
    animation: slideRight 70s linear infinite;
  }
  .example-image #export-image .award:nth-child(3) .nominees {
    animation: slideLeft 65s linear infinite;
  }
}

.example-image #export-image .award-icon {
  /* transition: opacity 1s ease; */
}

.example-image #export-image .nominee-wrap::before {
  /* transition: opacity 1s ease; */
}

@keyframes slideLeft {
  to {
    transform: translateX(calc(-100% * (2/3)));
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(calc(-100% * (2/3)));
  }
  100% {
    transform: translateX(0%);
  }
}