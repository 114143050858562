@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=DM+Sans:opsz,wght@9..40,600&family=Bebas+Neue&family=Lilita+One&family=Passion+One&family=Patua+One&family=Paytone+One&family=Tenor+Sans&family=Sriracha&family=DM+Serif+Display&family=Prata&display=swap');

.main-page {
  display: flex;
  flex-direction: row;
}

.footer {
  background-color: #ebeded;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1) inset; 
  display: flex;
  justify-content: space-between;
  align-items: flex-start;; /* TODO: remove if spotify logo is removed */
  padding: 20px 10px;
  font-family: 'Inter', sans-serif;
  color: rgba(0, 0, 0, 0.5);
  /* height: 100%; */
}

.footer-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center; /* TODO: remove if spotify logo is removed */
}

.footer a {
  opacity: 0.75;
}

.home-button {
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}
.home-button:visited {
  color: black;
}

.example-awards {
  flex: 1;
  padding: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-image {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  padding: 10px;
  box-sizing: border-box;
}

.description-block {
  margin: 10px 0px;
}

.description-title {
  font-size: 35px;
  font-weight: 600;
  max-width: 500px;
  text-align: center;
}

.sign-in {
  background-color: #1db954;
  color: white;
  width: 200px;
  height: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  transform: scale(1);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.info-modal {
  background-color: white;
  width: 80%;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  max-height: 85%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: scroll;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.info-title {
  font-size: 40px;
  text-align: center;
  font-family: Bebas Neue;
  margin: 0px 0px 10px 0px;
}

.info-block {
  margin: 10px 0px;
}

.info-block-header {
  font-weight: 700;
  margin-bottom: 5px;
}

a {
  color: #0041c2;
}

a:visited {
  color: #6f2da8;
}

.info-close {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}

.info-close-button-mini {
  text-align: left;
  display: flex;
  justify-content: flex-end;
}

#info-close-button-mini {
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: scale(1);
  margin: -10px 0px 5px 0px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

#info-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  background-color: black;
  width: 150px;
  height: 50px;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: scale(1);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  margin-top: 10px;
}

@media (hover: hover) {
  #info-close-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
  }
  #info-close-button-mini:hover {
    transform: scale(1.3);
  }
  .sign-in:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
    transform: scale(1.1);
  }
  .sign-in:active {
    transform: scale(1);
  }
}

/* Spotify dev guidelines say logo can't be <70px */
.footer-content img {
  min-width: 70px;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

@media (pointer: coarse) {
  .sign-in:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  #info-close-button-mini:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  #info-close-button:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  @keyframes bounce {
    to { transform: scale(0.9); }
  }
}

@media screen and (max-width: 700px) {
  .main-page {
    flex-direction: column;
  }
  
  .example-awards {
    padding-bottom: 5px;
  }
  
  /* .footer {
    flex-direction: column;
  }
  .footer-content {
    margin: 10px 0px;
  } */
}

@media screen and (max-width: 700px) {

  .footer-inner {
    flex-direction: column-reverse;
  }
  
  #credit-2 {
    margin-top: 10px;
  }

}

@media screen and (max-width: 500px) {

  .info-modal {
    width: 90%;
    padding: 20px;
  }

}

