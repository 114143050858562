@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;800&family=DM+Sans:opsz,wght@9..40,600&family=Bebas+Neue&family=Lilita+One&family=Passion+One&family=Patua+One&family=Paytone+One&family=Tenor+Sans&family=Sriracha&family=DM+Serif+Display&family=Prata&display=swap');

body {
  margin: 0px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-body {
  display: flex;
  flex-direction: row;
}

.button {
  margin: 10px 20px;
  font-size: 30px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  transform: scale(1);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.button span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Bebas Neue;
  font-size: 40px;
  height: 90vh;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-family: Bebas Neue; 
}

.menu {
  display: flex;
  justify-content: space-between;
  /* position: sticky;
  top: 0;
  z-index: 5; */
  background-color: white;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1); 
}

 .awards {
  flex: 1;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  font-family: Bebas Neue;
  padding-bottom: 20px;
}

 .award {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
/* OLD SHADOWS (more spread)
.award:nth-child(1) {
   box-shadow: 15px 0px 15px -15px rgba(0, 0, 0, 0.5) inset, -15px 0px 15px -15px rgba(0, 0, 0, 0.5) inset, 0px -5px 20px -5px rgba(0, 0, 0, 0.5) inset, 0px 15px 15px -15px rgba(0, 0, 0, 0.5) inset;
}
.award:nth-child(2) {
  box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.5) inset, -10px 0px 10px -10px rgba(0, 0, 0, 0.5) inset, 0px -5px 20px -5px rgba(0, 0, 0, 0.5) inset;
}

.award:nth-child(3) {
  box-shadow: 5px 0px 5px -5px rgba(0, 0, 0, 0.5) inset, -5px 0px 5px -5px rgba(0, 0, 0, 0.5) inset, 0px -5px 5px -5px rgba(0, 0, 0, 0.5) inset;
} */

.award:nth-child(1) {
   box-shadow: 15px 0px 10px -15px rgba(0, 0, 0, 0.5) inset, -15px 0px 10px -15px rgba(0, 0, 0, 0.5) inset, 0px -5px 7px -5px rgba(0, 0, 0, 0.5) inset, 0px 15px 10px -15px rgba(0, 0, 0, 0.5) inset;
}
.award:nth-child(2) {
  box-shadow: 10px 0px 7px -10px rgba(0, 0, 0, 0.5) inset, -10px 0px 7px -10px rgba(0, 0, 0, 0.5) inset, 0px -5px 7px -5px rgba(0, 0, 0, 0.5) inset;
}

.award:nth-child(3) {
  box-shadow: 5px 0px 5px -5px rgba(0, 0, 0, 0.5) inset, -5px 0px 5px -5px rgba(0, 0, 0, 0.5) inset, 0px -5px 7px -5px rgba(0, 0, 0, 0.5) inset;
}

 .category-title {
  font-size: 3em;
  /* height: 20%; */
  padding: 0px 0.2em; /* NOTE: this padding is to make the title align with album cover */
  /* border: 1px red solid; */
  display: flex;
  align-items: center;
  /* border: 1px lime solid; */
  margin-bottom: -0.2em;
}
.category-title span {
 /* display: flex;
 align-items: center; */
 /* border: 1px green solid; */
 height: 100%;
}

.category-icon {
  /* border: 1px black solid; */
  /* height: 100%; */
  /* margin-right: 0.1em; */
}

 .nominees {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 10px; */
  /* border: 1px blue solid; */
}

 .nominees-wrap {
  display: flex;
  align-items: flex-start;
  height: 100%;
  /* border: 1px blue solid; */
}

 .nominee {
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* border: 1px red solid; */
  align-items: flex-start;
  justify-content: space-between;
  /* padding-top: calc(0.755cqw); */
  height: 100%;
}

 .nominee-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: visible;
  height: 100%;
  margin: 1%;
  /* margin-bottom: 0; */
  position: relative;
  /* margin-bottom: 22%; */
  margin-bottom: 7%;
  border-radius: 0.5em;
  /* border: 1px green solid; */
  /* transition: background 2s linear; */
  /* background: rgba(0, 0, 0, 1); */
}

.nominee-wrap::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5em;
    background-color: rgba(150, 150, 150, 0.15);
    background: rgb(70,37,35);
    background: linear-gradient(326deg, rgba(70,37,35,1) 0%, rgba(203,155,81,1) 54%, rgba(203,155,81,1) 60%, rgba(203,155,81,1) 65%, rgba(246,226,122,1) 100%, rgba(70,37,35,1) 100%);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4); 
    /* background-size: cover; */
    /* background-position: 0 -50%; */
    background-repeat: no-repeat;
    background-size: 100% 101%;
    /* box-sizing: border-box; */
    overflow: hidden;
    background-position: center;
    opacity: 0;
    overflow: hidden;
    border: none;
}

.winner .nominee-wrap::before {
    opacity: 1;
}

.nominee-inner-wrap {
  /* border: 1px green solid; */
  margin: 7%;
  /* margin-bottom: 15%; */
  /* height: 100%; */
  /* z-index: 5; */
}

.spotify-link {
  /* border: 1px green solid; */
  margin: 2% auto;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.spotify-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10em;
  background-color: #1db954;
  font-family: 'Inter';
  font-size: 0.7em;
  /* padding: 5% 0; */
  color: white;
  cursor: pointer;
  /* font-weight: bold; */
  transition: transform 0.3s ease;
  transform: scale(1);
  text-decoration: none;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2); 
  /* border: 1px black solid; */
}

.spotify-button:visited {
  color: white;
}

.spotify-button div {
  /* border: 1px blue solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spotify-icon {
  width: 20%;
  margin-right: 3%;
  margin-top: 5%;
  margin-bottom: 5%;
}


.grammify {
  /* border: 1px black solid; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 3% 0;
  height: 100%;
}

.award-icon {
  width: 30%;
  height: auto;
  border: none;
  box-shadow: none;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  border-radius: 0 0 0.5em 0;
}

.winner .award-icon {
  opacity: 20%;
}

.winner {
/* --corner: 7px;
--bg: black;
background-image:
  radial-gradient(calc(2 * var(--corner)) at    0    0, transparent 50%, var(--bg) 50%, var(--bg) 99.99%, transparent 99.99%),
  radial-gradient(calc(2 * var(--corner)) at    0 100%, transparent 50%, var(--bg) 50%, var(--bg) 99.99%, transparent 99.99%),
  radial-gradient(calc(2 * var(--corner)) at 100% 100%, transparent 50%, var(--bg) 50%, var(--bg) 99.99%, transparent 99.99%),
  radial-gradient(calc(2 * var(--corner)) at 100%    0, transparent 50%, var(--bg) 50%, var(--bg) 99.99%, transparent 99.99%),
  linear-gradient(to  right, transparent var(--corner), var(--bg) var(--corner), var(--bg) calc(100% - var(--corner)), transparent calc(100% - var(--corner))),
  linear-gradient(to bottom, transparent var(--corner), var(--bg) var(--corner), var(--bg) calc(100% - var(--corner)), transparent calc(100% - var(--corner))); */
  /* border-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cfilter id='blurMe'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='3' /%3E%3C/filter%3E%3Csymbol id='card' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath d='M10,0h60c7,1+9,3+10,10v60h-10v10h-60a10,10+0,00-10-10v-60Z'/%3E%3C/symbol%3E%3C/defs%3E%3Cuse xlink:href='%23card' style='fill:%23000; opacity: 0.4; filter:url(%23blurMe);' x='10' y='15' width='80' height='80'/%3E%3Cuse xlink:href='%23card' style='fill:%23000' x='10' y='10' width='80' height='80'/%3E%3C/svg%3E%0A") 20 fill / 20px / 10px stretch; */
  /* border: 10px ridge #a17c23; */
  /* border-image: linear-gradient(to right, #a17c23 0%, #e6a90b 100%) 1; */
  /* box-sizing: border-box; */
  /* background: black; */
}

 .image-wrap {
  width: 100%; /* TODO: use better method...? */
  /* padding-top: 2.5%; */
  overflow: visible;
  text-align: center;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}


 .nominee-img {
  width: 100%;
  vertical-align: bottom;
  object-fit: cover;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4); 
  aspect-ratio: 1/1;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  transition: box-shadow 0.3s ease;
}

/* .winner .nominee-img {
  box-shadow:
    0 0 5px rgba(70, 37, 35, 0.9), 
    0 0 10px rgba(203, 155, 81, 0.8),   
    0 0 15px rgba(203, 155, 81, 0.6), 
    0 0 20px rgba(246, 226, 122, 0.4), 
    0 0 25px rgba(246, 242, 192, 0.2); 
} */

 .grammy {
  width: 43%;
  /* position: absolute; */
  /* bottom: 0px; */
  /* right: 8px; */
  /* left: 65%; */
  top: -210%;
  border-radius: 50%;
  /* filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5)); */
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4); 
  /* display: none; */
  opacity: 0;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  transition: box-shadow 0.3s ease;
  display: none; 
}

.winner .grammy {
  opacity: 1;
}

 .nominee-name {
  width: 100%;
  /* align-self: flex-start; */
  font-size: 1em;
  overflow-wrap: anywhere;
  margin-top: 6px;
  position: relative;
  z-index: 2;
  /* background: black; */
  /* text-shadow: 
  0 0 2px rgba(255, 215, 0, 0.8),  
  0 0 4px rgba(255, 215, 0, 0.6), 
  0 0 6px rgba(255, 215, 0, 0.4), 
  0 0 8px rgba(255, 215, 0, 0.2), 
  0 0 10px rgba(255, 215, 0, 0.1);  */
  /* text-shadow: 0 0 1px #fff, 0 0 20px #fff, 0 0 3px #e60073, 0 0 4px #e60073, 0 0 5px #e60073, 0 0 6px #e60073, 0 0 7px #e60073; */
}

.nominee-title {
  /* border: 1px green solid; */
  /* display: inline-block; */
}

/* .winner .nominee-title {
  background-image: linear-gradient(
	to right,
	#462523 0,
       	#cb9b51 22%, 
	#f6e27a 45%,
	#f6f2c0 50%,
	#f6e27a 55%,
	#cb9b51 78%,
	#462523 100%
	);
   color:transparent;
   -webkit-background-clip:text;
} */

 .nominee-details {
  opacity: 0.5;
}


/****/

.control-panel {
  flex: 0.8;
  display: flex;
  justify-content: center; /* TODO center or flex start? */
  align-items: center;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 10px;
}

.save-and-share-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-and-share {
  background-color: black;
  color: white;
  width: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: scale(1);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.theme-selector-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  /* padding: 0px 10px; */
}
.theme-wrap {
  display: flex;
  justify-content: center;
}

.theme-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme-label {
  margin-top: 5px;
  cursor: pointer;
}

.option-button {
  height: 40px;
  width: 40px;
  user-select: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: scale(1);
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
}

.option-button:active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 -2px 10px -2px rgba(0,0,0,0.5); 
}

.active-option span {
  display: block;
}

.active-option {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5) inset, inset 0 -2px 10px -2px rgba(0,0,0,0.5); 
}

.controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  padding: 10px 20px;
  max-width: 700px;
}

.controls-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.control-title {
  font-size: 20px;
  font-weight: 700;
}

.control-options {
  margin: 15px 0px;
}

.control {
  font-size: 18px;
  margin: 10px 0px; 
}

.winner-selector {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: 10px 0px;
  font-size: 18px;
}

.winner-selector-options {
  display: flex;
  flex-direction: column;
  background: teal;
  width: 100%;
}

.winner-selector-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
}
.winner-selector-option-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.winner-selector-option-label {
  margin-left: 7px;
  cursor: pointer;
}

.user-select {
  transition: transform 0.3s ease;
  transform: scale(1);
  cursor: pointer;
}


@media (hover: hover) {
  .user-select:hover {
    transform: scale(1.1);
  }
  
  .spotify-button:hover {
    transform: scale(1.1);
    box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.2);
  }
  
  .user-select:hover {
    box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.4);
  }
  
  .user-select:hover .grammy {
    /* display: block; */
    opacity: 0.75;
  }
  
  .user-select:hover .award-icon {
    opacity: 20%;
  }
  
  .user-select:hover + .spotify-link > .spotify-button {
    transform: translateY(0.3rem);
  }
  
  .button:hover span {
    transform: scale(1);
  }

  .button:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
    transform: scale(1.1);
  }

  .save-and-share:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
  }
  
  .export-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
  }
  
  .export-close-button:hover {
    opacity: 0.6;
  }

  .option-button:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
    transform: scale(1.1);
  }

  .option-button:hover span {
    display: block;
  }

  .active-option:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 -2px 10px -2px rgba(0,0,0,0.5); 
  }
  
  .button:active {
    transform: scale(1);
  }

  .save-and-share:active {
    transform: scale(1);
  }
  
  .export-button:active {
    transform: scale(1);
  }
  .export-close-button:active {
    opacity: 0.4;
  }
  .search-submit:hover {
    color: white;
    background: #b89a5a;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 0px 5px -2px rgba(0,0,0,0.5); 
  }
  
  .result-block li:hover {
    /* background: black; */
    background: #b89a5a;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 0px 5px -2px rgba(0,0,0,0.5); 
  }
  
  .empty-nominee:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
  }
}

/* #credit-2 {
  display: none;
} */

@media (pointer: coarse) {
  .user-select:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  
  .save-and-share:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  .export-button:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  .export-close-button:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  .button:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  
  .empty-nominee:active {
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  
  .hover-active  .nominee-inner-wrap .image-wrap {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .search-submit:active {
    color: white;
    background: #b89a5a;
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 0px 5px -2px rgba(0,0,0,0.5);  */
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }
  
  .result-block li:active {
    background: #b89a5a;
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 0px 5px -2px rgba(0,0,0,0.5);  */
    animation: bounce .1s infinite alternate;
    animation-iteration-count: 2;
  }

  @keyframes bounce {
    to { transform: scale(0.9); }
  }
}


.hidden-nominee {
  visibility: hidden;
}

.empty-nominee {
  transition: transform 0.3s ease;
  transform: scale(1);
  border:  3px rgba(255, 255, 255, 0.5) dashed;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

@media (pointer: fine) {
  .hover-active .nominee-inner-wrap .image-wrap {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
  }
}

.plus {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 35px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.choose-nominee-modal {
  width: 500px;
  max-width: 90vw;
  height: 100%;
  font-family: 'Inter', sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 1%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  flex-flow: column;
  color: black;
  /* overflow: hidden; */
  font-size: 30px;
}

.search-block {
  display: flex;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.choose-nominee-modal > * {
  /* background-color: white; */
}

.search-block > * {
  /* border: 1px blue solid; */
  /* height: 40px; */
}

.search-bar {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  width: calc(100% - 50px);
  /* margin-right: 10px; */
}

#searchInput:focus {
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 0px 5px -2px rgba(0,0,0,0.5); 
}

#searchInput {
  /* background-color: rgba(0,0,0,0.2); */
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 5px;
  border-style: none;
  padding: 0px 7px;
  top: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.4), 0 0px 3px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */


}

.search-submit {
  width: 40px;
  height: 40px;
  background: white;
  color: #b89a5a;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.4), 0 0px 3px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
  /* transition: transform 0.3s ease; */
  transition-duration: 0.2s;
  cursor: pointer;
}

.result-block {
  width: 100%;
  /* height: auto; */
  max-height: 60%;
  margin-top: 20px;
  background: white;
  display: flex;
  /* justify-content: space-between; */
  padding: 0px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.result-block ul {
  list-style: none;
  padding-left: 0;
  /* border: 1px black solid; */
  margin: 10px 0px;
}

.no-results {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.result-block li {
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.4), 0 0px 3px 0 rgba(0, 0, 0, 0.19); /* from https://www.w3schools.com/css/css3_shadows_box.asp */
  transition-duration: 0.2s;
  cursor: pointer;
  /* padding-left: 7px; */
}

.result-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  font-size: 15px;
}

.result-name {
  font-weight: bold;
}

.search-img {
  height: 55px;
  width: 55px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4); 
  vertical-align: bottom;
  object-fit: cover;
  aspect-ratio: 1/1;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  background-color: grey;
}

.spotify-button p {
  display: inline-block;
  margin: 5% 0;
}

/************/

.palletePicker {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  /* margin-bottom: 20px; */
}

.palletePicker form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 5px 0px; */
}

.palletePicker input[type=text] {
  margin: 5px;
  border-radius: 5px;
  height: 25px;
  padding: 5px;
  font-size: 16px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5) inset, inset 0 0px 5px -2px rgba(0,0,0,0.5); 
  border: none;
  /* text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black; */
  color: white;
  width: 95%;
  text-align: center;
}

#submit-palette {
  border: none;
  /* width: 110px; */
  width: 100%;
  height: 35px;
  font-size: 16px;
  background-color: grey;
  border-radius: 5px;
  margin: 5px;
}

/************/

@media screen and (max-width: 1230px) {
  .spotify-icon {
    width: 17%;
  }
}

@media screen and (max-width: 1170px) {
  .spotify-icon {
    width: 17%;
  }
  
  .spotify-button {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 1090px) {
  .spotify-button {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 1000px) {
  #spotify-string {
    display: none;
  }
  
  .spotify-button p {
    margin-top: 10%;
    margin-bottom: 10%;
    font-size: 10px;
  }
}

@media screen and (max-width: 850px) {
  .spotify-button {
    font-size: 0.5em;
  }
}

@media screen and (max-width: 800px) {
  
  .main-body {
    flex-direction: column;
  }
  
  .controls {
    /* flex-direction: column-reverse; */
    padding: 0px 0px 10px 0px;
  }
  .control-panel {
    margin: 0px 20px;
  }
  
  #user-grammify .awards {
    margin: 10px;
  }
  
  /* #credit-1 {
    display: none;
  }
  
  #credit-2 {
    display: block;
  } */
  
  #spotify-string {
    display: inline-block;
  }
  
  .spotify-button {
    font-size: 0.7em;
  }
  
  .spotify-button p {
    margin-top: 5%;
    margin-bottom: 5%;
  }

}

@media screen and (max-width: 680px) {
  .spotify-button {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 600px) {
  
  #user-grammify .nominee-name {
   font-size: 0.8em;
   margin-top: 4.5px;
 }
 
 #user-grammify .category-title {
    font-size: 2em;
    padding: 0px 0.1em; /* NOTE: this padding is to make the title align with album cover */
  }
  
}

@media screen and (max-width: 590px) {
  .spotify-button {
    font-size: 0.5em;
  }
}

@media screen and (max-width: 590px) {
  .spotify-button p {
    /* font-size: 0.9em; */
    margin: 0;
  }
  
  .spotify-icon {
    width: 25%;
  }
  
  #spotify-string {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  /* .alert-header {
    font-size: 33px; 
  } */
  #user-grammify .awards {
    padding: 0px;
    margin: 6px;
  }
  
  .control-panel {
    margin-left: 10px;
    margin-top: 5px;
  }
  
  #user-grammify .award {
    padding: 5px 2px;
  }
}

@media screen and (min-width: 1500px) {
  .spotify-button p {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1700px) {
  .spotify-icon {
    /* width: 10%; */
  }
  
  .spotify-button p {
    font-size: 16px;
  }
  
  .nominee {
    font-size: 1.3em;
  }
  
  .award-icon {
    width: 25%;
  }
}